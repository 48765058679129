body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
body{
     background: #e7efefd6;
}
.ag-header {
    background: #fff !important;
}
.ag-theme-alpine .ag-row:hover {
  background: #f1f1f1 !important;
}
.ag-row-odd .ag-row:hover {
  background: #f1f1f1 !important;
}
.ag-row-odd{
	background: none;
}
.ag-theme-alpine .ag-root-wrapper {
    border-color: #dde2eb !important;
}
.ag-theme-alpine .ag-row{
	 border-color: #dde2eb !important;
}

.footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 30px;
  background: #fff;
  font-size: 12px;
  align-items: center;
  display: flex;
  border-top: 1px solid #d4d1d1;
  z-index: 99;
}
.inner-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.image-container img {
  width: 32px;
  height: 100%;
  z-index: 999;
  top: 2px;
  right: 8px;
  width: 28px;
  height: 100%;
  z-index: 999;
  top: 2px;
  right: 8px;
  border: 1px solid #959595;
  border-radius: 50%;
  box-shadow: 0 19px 38px rgba(0,0,0,.3),0 15px 12px rgba(0,0,0,.22);
}
.image-container .caption-4 {
  right: -90px;
  transition-duration: 1.5s;
  transition-timing-function: linear;
  top: 8px;
  visibility: hidden;
  z-index: -99;
}

.image-container .image-caption {
  position: absolute;
  background-color: #fff;
  text-align: center;
}
.image-container .caption-4 {
  right: -110px;
  transition: all 1.8s;
}
.image-caption p {
  font-size: 14px;
  color: #000;
  font-weight: bold;
}
.image-container:hover .caption-4{
  right:65px;
  transition: all 1.8s;
  visibility: visible;
  z-index: -99;
}
input#form1::placeholder {
  font-size: 14px;
}
.testtt {
  position: fixed;
  bottom: 0px;
  right: 0px;
  z-index: 999999999;
  width: 60px;
  background: #fff;
}
.formheading{
	text-align: left;
font-size: 20px;
padding-bottom: 18px;
}

.lowerdiv {
    margin-top: 25px;
}
.ew-container {
  margin: 10px 12px!important;
  padding: 0 !important;
  background: #fff;
  position: fixed;
  bottom: 35px;
  left: 0px;
  right: 0px;
  top: 125px;
  max-height: 480px;
  overflow: auto;
}
.ex-btnSearch {
  font-size: 13px !important;
  color: #fff !important;
  padding: 7px 24px !important;
}
.ag-theme-alpine{
  position: fixed;
  left: 0;
  right: 0;
  top: 134px;
  bottom: 29px;
  max-height: 495px;
  overflow: auto;
}
.ew-rolecreate {
  margin-bottom: 0px !important;
}
.ewp-container {
  max-height: 480px;
  overflow: auto;
  padding: 0;
  background: #fff;
}
.ewp-footer{
  padding:0 1.5rem !important;
}
.ag-after-created{
  cursor: pointer;
}
.ag-after-created:hover .View_btn{
  visibility: visible;
}
.View_btn {
  font-size: 12px;
  visibility: hidden;
}
.roleTop {
  padding: 10px 12px 5px;
  margin: 0 12px;
}
.role-srch {
  margin-bottom: 0;
}
.nav-link{
  padding: 0.375rem 0.75rem !important;
}
.form-control{
  border-radius: 0 !important;
}
.btn{
  border-radius: 0 !important;
}
.ew-inputRole {
  height: 37px !important;
}
.loader{
  width:70px;
}
.btn-primary:focus , .btn{
  box-shadow: none !important;
}