.row-form {
  width: 400px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  background: #fff;
  padding: 20px 0px;
}

.form-style input {
  border: 0;
  border-radius: 0;
  margin-left: 5px;
  margin-right: 4px;
}
.form-control:focus {
  border-color: #ced4da !important;
  box-shadow: none !important;
}

.form-style .faw-log {
  padding-top: 12px;
  font-size: 28px;
  color: #4d4d4d;
}

.form-log_in {
  display: flex;
  border: none;
  border-bottom: 1px solid #ebebeb;
  margin: 0;
  margin-top: 12px;
  background: #f3f1f1 !important;
  border: 1px solid #ddd;
}



.form-style input:focus {
  box-shadow: none;
  outline: 0;
}
.sideline {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #ccc;
}
button {
  height: 50px;
}
.sideline:before,
.sideline:after {
  content: "";
  border-top: 1px solid #ebebeb;
  margin: 0 20px 0 0;
  flex: 1 0 20px;
}

.sideline:after {
  margin: 0 0 0 20px;
}

.cont-form {
  display: flex;
  justify-content: center;
}

.form-heading {
  font-size: 25px;
}

.submit-form {
  margin-bottom: 0.5rem !important;
}

.btn-form {
  box-shadow: none;
  background: #036de3 !important;
  border-radius: 0px !important;
}

.btn-form:hover {
  border: none;
}

.btn-imp {
  width: 90%;
  margin: 0 auto;
}

.log-remember {
  padding: 13px 0px 0px 11px;
}

.log-figure {
  margin-top: 10px;
  display: flex;
  justify-content: center;
}

.cont {
  margin: 8px auto 3px;
  background-color: white;
  border: 1px #bab5b5 solid;
  /* border: 2px red solid; */
  width: 420px;
  box-shadow: 2px 3px 10px rgb(184, 184, 184);
}

#main-title {
  /* border: 2px red solid; */
  text-align: center;
  font-size: 25px;
  padding: 30px 30px 10px 30px;
  font-weight: 700;
  color: rgb(20, 20, 20);
}

#subtitle {
  text-align: center;
  font-size: 14px;
  color: rgb(70, 70, 70);
}

form {
  padding: 14px 15px 3px 15px;
}

.placeholders {
  /* border: 2px red solid; */
  display: flex;
  width: 100%;
  margin-bottom: 18px;
  border: none;
  border-bottom: 1px solid !important;
}

.placeholders #cars {
  width: 100%;
  background: #fff;
  border: none;
  height: 36px;
  box-shadow: none;
  color: gray;
}

.placeholders .faw-input {
  padding-top: 10px;
  font-size: 24px;
  color: #313131;
}

.placeholders > label {
  display: block;
  margin-bottom: 10px;
  font-size: 12px;
  /* border: 2px red solid; */
}

.placeholders input {
  /* border: 2px red solid; */
  width: 100%;
  height: 35px;
  padding: 0 0 0 7px;
  border: none;
}

.placeholders input:focus {
  outline: none;
}

input::placeholder {
  font-family: "Montserrat";
  /* border: 2px red solid; */
}

.terms-conditions {
  margin-bottom: 12px;
  /* border: 2px red solid; */
}

#terms-conditions {
  display: inline;
  font-size: 13px;
  color: black;
  /* border: 2px red solid; */
}

#a-submit {
  display: block;
  margin: 0px auto 0px;
  text-align: center;
  padding: 0px 0px 0px 0px;

  background-color: rgb(237, 28, 36);
  border-radius: 3px;
  cursor: pointer;
  width: 85%;
  font-size: 15px;
  color: white;
  font-weight: 700;
  --webkit-transition: all 0.43s ease;
}

#a-submit:active {
  width: 40%;
}

.btn-imp {
  width: 100%;
  margin: 0 auto;
}

button.btn.btn-dark.w-100.mt-2.btn-form.btn-log {
  width: 100%;
  border: none;
  color: #fff;
  font-size: 16px;
}

.cont figure {
  padding: 9px 0 0 0;
}

figure a img {
  max-width: 100%;
  height: auto;
  display: block;
  margin: 0 auto;
}

.register-already {
  padding: 10px 0px;
  text-align: center;
}

.register-already span a {
  padding-left: 7px;
  color: red;
}

btn-log {
  background: red !important;
}

.form-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f4f5f8;
  padding: 75px 0;
  margin: 0;
}

.cont {
  margin: 8px auto 3px;
  background-color: white;
  border: 1px #bab5b5 solid;
  width: 420px;
  box-shadow: 2px 3px 10px rgb(184, 184, 184);
}

.cont figure {
  padding: 20px 0 0 0;
  margin-top: 0;
}

figure a img {
  max-width: 100%;
  height: auto;
  display: block;
  margin: 0 auto;
}

.user {
  display: flex;
  max-width: 100%;
  margin: 0 auto;
  padding-top: 10px;
  border: 1px solid #7d7d7d;
  font-size: 30px;
  padding: 20px;
  border-radius: 50%;
  vertical-align: middle;
  color: #033e3e;
  width: 70px;
  height: 70px;
}

form {
  padding: 14px 15px 3px 15px;
}

.placeholders {
  /* border: 2px red solid; */
  display: flex;
  width: 100%;
  margin-bottom: 18px;
  border: none;
  border-bottom: 1px solid !important;
}

.placeholders #cars {
  width: 100%;
  background: #fff;
  border: none;
  height: 36px;
  box-shadow: none;
  color: gray;
}

.placeholders .faw-input {
  padding-top: 10px;
  font-size: 24px;
  color: #313131;
}

.placeholders > label {
  display: block;
  margin-bottom: 10px;
  font-size: 12px;
  /* border: 2px red solid; */
}

.placeholders input {
  /* border: 2px red solid; */
  width: 100%;
  height: 35px;
  padding: 0 0 0 7px;
  border: none;
}

.placeholders input:focus {
  outline: none;
}

h2 {
  text-align: center;
  margin: 0 0 20px;
}

.form {
  padding: 15px 25px 13px 25px;
  margin-bottom: 0;
}


.form-ok {
  padding: 8px 0.75rem !important;
  background: #f3f1f1 !important;
}

.form-control label {
  color: #777;
  display: block;
  margin-bottom: 5px;
}

.form-control input {
  display: block;
  width: 100%;
  padding: 10px;
  font-size: 14px;
  border: none;
}

.form-control input:focus {
  outline: 0;
  border-color: #777;
}

.form-control.success input {
  border-color: var(--succes-color);
}

.form-control.error input {
  border-color: var(--error-color);
}

.form-control small {
  color: var(--error-color);
  position: absolute;
  bottom: -19;
  left: 0;
  visibility: hidden;
}

.form-control.error small {
  visibility: visible;
}
.form button {
  cursor: pointer;
  background-color: #ed2929;
  border: 2px solid #ed2929;
  border-radius: 4px;
  color: #fff;
  display: block;
  padding: 10px;
  font-size: 16px;
  margin-top: 24px;
  width: 100%;
  outline: none;
  /* width: 90%;
  margin: 28px auto 0px; */
}

.register-already {
  padding: 10px 0px;
  text-align: center;
}

.register-already span a {
  padding-left: 7px;
  color: red;
  text-decoration: none;
}

.form .form-control .faw-input {
  padding-top: 10px;
  font-size: 28px;
  color: 4d4d4d;
}
.ew-primary-col {
  background: #e12b13 !important;
}
.ew-primary-col:focus {
  outline: none !important;
  box-shadow: none !important;
}
.ew-primary-col:hover {
  background: #e12b13 !important;
}
.ew-primary-col-text {
  color: #fff !important;
}
.error-msg {
  margin-left: 10px;
}
.ew-form-control {
  border: none !important;
  border-bottom: 1px solid #ccc !important;
}
.ew-svg {
  margin-top: 7px;
  margin-left: 12px;
}
.err-container {
  margin-top: 10px;
  height: 10px;
}
.err-container .errp {
  margin: 0;
  color: red;
  text-align: center;
}
.welcome-text {
  margin-top: 9px;
}

.welcome-text h2 {
  font-size: 24px;
  color: #033e3e;
}
.ewp-log {
  padding: 13px 0px !important;
}
.ewp-drp {
  top: 58px !important;
}
.errdiv {
  height: 30px;
  margin-top: -12px;
}
.errdiv {
  display: flex;
  align-items: center;
  justify-content: center;
}

/*  */
.errmsg {
  color: red;
  margin-top: 25px;
  font-family: "Roboto", sans-serif;
  background: #ffdede85;
  height: 36px;
  width: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 20px;
}
.successmsg {
  color: #289a45;
  margin-top: 25px;
  font-family: "Roboto", sans-serif;
  background: #d0f1d096;
  height: 36px;
  width: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 20px;
}
.bg-bck {
  background-color: #fff !important;
  box-shadow: 0 2px 4px rgb(0 0 0 / 8%), 0 4px 12px rgb(0 0 0 / 8%);
}
.ewp-navbar-brand {
  color: #fff;
  text-decoration: none;
}
.ewp-navbar-brand:hover {
  color: #fff;
  text-decoration: none;
}
.portal_img {
  max-width: 230px;
  height: auto;
}
